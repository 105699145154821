// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "C_vK d_bD";
export var storyRowWrapper = "C_hx d_hx d_bK";
export var storyLeftWrapper = "C_vL d_bz d_bP";
export var storyWrapperFull = "C_vM d_cD";
export var storyWrapperFullLeft = "C_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "C_mv d_hy";
export var storyLeftWrapperCenter = "C_vN d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "C_vP d_hF";
export var storyHeader = "C_vQ d_hD d_w d_cs";
export var storyHeaderCenter = "C_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "C_hB d_hB d_by d_dw";
export var storyBtnWrapper = "C_vR d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "C_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "C_rj d_fg d_Z";
export var imageWrapperFull = "C_vS d_w d_H d_bf d_Z";
export var SubtitleSmall = "C_pd y_pd y_sR y_s2";
export var SubtitleNormal = "C_pf y_pf y_sR y_s3";
export var SubtitleLarge = "C_pg y_pg y_sR y_s4";
export var textLeft = "C_dv";
export var textCenter = "C_dw";
export var textRight = "C_dx";