// extracted by mini-css-extract-plugin
export var alignDiscLeft = "B_vh d_fp d_bG d_dv";
export var alignLeft = "B_rd d_fp d_bG d_dv";
export var alignDiscCenter = "B_vj d_fq d_bD d_dw";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignDiscRight = "B_vk d_fr d_bH d_dx";
export var alignRight = "B_rf d_fr d_bH d_dx";
export var footerContainer = "B_vl d_dW d_bW";
export var footerContainerFull = "B_vm d_dT d_bW";
export var footerHeader = "B_kf d_kf";
export var footerTextWrapper = "B_vn d_w";
export var footerDisclaimerWrapper = "B_km d_km d_ck";
export var badgeWrapper = "B_vp d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "B_vq d_bz d_bJ d_bN d_bL";
export var wide = "B_vr d_cy";
export var right = "B_vs d_bK";
export var line = "B_fk d_fl d_cv";
export var badgeDisclaimer = "B_vt d_bC d_bP d_bJ";
export var badgeContainer = "B_vv d_bz d_bH d_bP";
export var badge = "B_vw";
export var padding = "B_vx d_c7";
export var end = "B_vy d_bH";
export var linkWrapper = "B_vz d_dB";
export var link = "B_mC d_dB";
export var colWrapper = "B_vB d_cx";
export var consent = "B_f d_f d_bC d_bP";
export var disclaimer = "B_vC d_bz d_bJ";
export var media = "B_vD d_bx d_dy";
export var itemRight = "B_vF";
export var itemLeft = "B_vG";
export var itemCenter = "B_vH";
export var exceptionWeight = "B_vJ y_th";